import React, { useState } from "react";
import Modal from 'react-modal';
import Markdown from 'react-markdown';
import styles from './styles.module.scss';

type Props = {
  title: string,
  price: number,
  content: string,
}

const Block = ({ title, price, content } : Props) => {
  const [modalOpen, toggleModal] = useState(false);
  return (
  <React.Fragment>
    <Modal
      isOpen={!!modalOpen}
      className="rateModal"
      closeTimeoutMS={250}
      // overlayClassName="rateModalOverlay"
    >
      <div className={styles.rateModalInner}>
        <button onClick={() => toggleModal(!modalOpen)}>close</button>
        <Markdown children={content} />
      </div>
    </Modal>
    <div className={styles.block}>
      <h4>{title}</h4>
      <p>${price?.toFixed(2).replace('.00', '')}<span>per day</span></p>
      <button
        onClick={() => toggleModal(!modalOpen)}
      >
        More information
        </button>
    </div>
  </React.Fragment>
)}

export default Block
